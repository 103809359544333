import { Component, OnInit, Renderer2 } from '@angular/core';
import { PermissionParserService } from '@core/services/permission-parser/permission-parser.service';
import { Store } from '@ngxs/store';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { AppStateService } from '@shared/services/app-state.service';
import { LoaderService } from '@shared/services/loader.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { PrimeNGConfig } from 'primeng/api';
import { CheckAuth } from './core/state/auth-state/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private _menuMode = this.safeGetFromLocalStorage('theme_menuMode', 'sidebar');
  public get menuMode() {
    return this._menuMode;
  }
  public set menuMode(value) {
    localStorage.setItem('theme_menuMode', JSON.stringify(value));
    this._menuMode = value;
  }

  private _layout = this.safeGetFromLocalStorage('theme_layout', 'green');
  public get layout() {
    return this._layout;
  }
  public set layout(value) {
    localStorage.setItem('theme_layout', JSON.stringify(value));
    this._layout = value;
  }

  private _theme = this.safeGetFromLocalStorage('theme_theme', 'green');
  public get theme() {
    return this._theme;
  }
  public set theme(value) {
    localStorage.setItem('theme_theme', JSON.stringify(value));
    this._theme = value;
  }

  private _ripple: boolean = this.safeGetFromLocalStorage('theme_ripple', null);
  public get ripple(): boolean {
    return this._ripple;
  }
  public set ripple(value: boolean) {
    localStorage.setItem('theme_ripple', JSON.stringify(value));
    this._ripple = value;
  }

  private _colorScheme = this.safeGetFromLocalStorage('theme_colorScheme', 'dark');
  public get colorScheme() {
    return this._colorScheme;
  }
  public set colorScheme(value) {
    localStorage.setItem('theme_colorScheme', JSON.stringify(value));
    this._colorScheme = value;
    this.appStateService.colorScheme = this._colorScheme;
    this.renderer.addClass(document.body, this.colorScheme == 'light' ? 'light-color-scheme' : 'dark-color-scheme');
    this.renderer.removeClass(document.body, this.colorScheme == 'light' ? 'dark-color-scheme' : 'light-color-scheme');
  }
  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store,
    public dialogService: AppDialogService,
    public toastService: ToastService,
    private permsParser: PermissionParserService,
    private viewModeService: ViewModeService,
    public loaderService: LoaderService,
    private renderer: Renderer2,
    private appStateService: AppStateService
  ) {
    store.dispatch(new CheckAuth());
    // this.permsParser.initializeUserPermissions();
  }

  ngOnInit() {
    this.primengConfig.ripple = this.safeGetFromLocalStorage('theme_ripple', true);
    this.ripple = this.safeGetFromLocalStorage('theme_ripple', true);
    this.appStateService.colorScheme = this._colorScheme;
    this.renderer.addClass(document.body, this.colorScheme == 'light' ? 'light-color-scheme' : 'dark-color-scheme');
    this.renderer.removeClass(document.body, this.colorScheme == 'light' ? 'dark-color-scheme' : 'light-color-scheme');
  }
  safeGetFromLocalStorage(key: string, defaultValue?: any) {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue;
  }
}
